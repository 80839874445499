.sx-table-row-draggable {
    tbody > tr {
        cursor: move;
    }
}

.helper-element {
    display: table-row;

    td {
        border-bottom: 1px solid #e8e8e8;
        padding: 16px 16px;
        display: table-cell;
        vertical-align: inherit;
        background: #fff;
        width: 10000px;
        border-collapse: collapse;
    }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #000;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);