.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
}

.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 50px;

    .header {
        text-align: center;

        h1 {
            margin: 0;
            font-size: 88px;
        }
    }

    .intro {
        margin-top: 16px;
        text-align: center;
    }
}


.error404 {
    background: url("./404.jpg") no-repeat center #fff;
    background-size: contain;
}

.error401 {
    background: url("./401.jpg") no-repeat center #fff;
    background-size: contain;
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #000;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);