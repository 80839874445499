/* 查询条件 */
.sx-query-bar {
    position: relative;
    padding: 24px 16px 8px 16px;
    margin-bottom: 16px;
    background: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    .sx-query-bar-collapsed {
        position: absolute;
        right: 0;
        top: 0;
        padding: 3px 8px 0 0;
    }
    .ant-form-item {
        margin-bottom: 16px;
    }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #000;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);