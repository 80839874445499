@headerHeight: 64px;
@transitionDuration: 300ms;
@layout-header-background : #001529;

.side {
    position: fixed;
    z-index: 999;
    top: @headerHeight;
    left: 0;
    bottom: 0;
    width: 200px;
    box-shadow: 2px 0 6px lighten(@layout-header-background, 10%);
    background: @layout-header-background;
    transition: all @transitionDuration;

    .outer {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        overflow: hidden;
        transition: all @transitionDuration;
    }

    .inner {
        position: relative;
        height: 100%;
        overflow-x: visible;
        overflow-y: scroll;
        transition: all @transitionDuration;
        -webkit-overflow-scrolling: touch;
    }

    .drag-bar {
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 100%;
        cursor: col-resize;
        z-index: 1;
    }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #000;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);