.operator-label {
  display: inline-block;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
.operator-label:hover {
  opacity: 0.6;
}
.operator-label-disabled {
  cursor: not-allowed;
}
.operator-label-disabled:hover {
  opacity: 1;
}
.operator-divider {
  display: inline-block;
  margin: 0 8px;
  width: 1px;
  height: 18px;
  vertical-align: middle;
  background: #e8e8e8;
}
