@headerHeight: 64px;

.header-menu {
    display: block;
    height: 100%;

    :global(.ant-menu-root) {
        border: 0;
        height: @headerHeight;
        line-height: @headerHeight - 2;
    }

    :global(.font-icon) {
        margin-right: 8px;
    }
}

// 这里用了个全局样式
:global(.ant-menu-submenu-popup .font-icon) {
    margin-right: 8px;
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #000;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);