.draggable-tabs-bar-root {
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px;
    box-sizing: border-box;
    overflow: hidden;
    padding-left: 2px;
}

.draggable-tabs-bar-root.sorting,
.draggable-tabs-bar-root.sorting * {
    cursor: move !important;
}

body > .draggable-tabs-bar-horizontal-item {
    z-index: 9999;
    transition: 0ms;
}

.draggable-tabs-bar-horizontal-item {
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    user-select: none;
    font-weight: 400;
    overflow: hidden;
}

.draggable-tabs-bar-horizontal-item.active .draggable-tabs-bar-wrapper,
.draggable-tabs-bar-horizontal-item:hover .draggable-tabs-bar-wrapper {
    background: #fff;
    color: #1890ff;
}

.draggable-tabs-bar-horizontal-item.active .close-wrapper:after,
.draggable-tabs-bar-horizontal-item:hover .close-wrapper:after {
    background: #fff;
    box-shadow: 0 0 10px 10px #fff;
}

.draggable-tabs-bar-horizontal-item .draggable-tabs-bar-horizontal-item-inner {
    display: flex;
    align-items: center;
    height: 100%;
    transition: width 300ms;
    box-sizing: border-box;
    padding-right: 2px;
}

.draggable-tabs-bar-horizontal-item .draggable-tabs-bar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    height: 100%;
    width: 100%;
    padding: 0 8px;
    border: 1px solid #e8e8e8;
    border-bottom: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #fafafa;
    color: #333;
    transition: 300ms;
}

.draggable-tabs-bar-horizontal-item .item-inner {
    display: flex;
    flex-grow: 1;
    align-items: center;
    position: relative;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
}

.draggable-tabs-bar-horizontal-item .close-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 16px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    cursor: pointer;
}

.draggable-tabs-bar-horizontal-item .close-wrapper:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -3px;
    height: 100%;
    width: 0;
    background: #fafafa;
    box-shadow: 0 0 10px 10px #fafafa;
    transition: 300ms;
}
