@headerHeight: 64px;
@transitionDuration: 300ms;
@layout-header-background: #001529;
@primary-color: #1890ff;

.header {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    height: @headerHeight;
    padding-right: 16px;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

    .logo {
        display: inline-flex;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
        height: @headerHeight;
        position: relative;
        overflow: hidden;
        background: @layout-header-background;
        box-shadow: 2px 0 6px lighten(@layout-header-background, 10%);

        h1 {
            color: #fff;
        }
    }

    .trigger {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
        height: 100%;
        font-size: 20px;
        cursor: pointer;
        transition: all .3s;
        padding: 0 24px;

        &:hover {
            opacity: 0.5;
        }
    }

    .center {
        flex-shrink: 1;
        flex-grow: 1;
        overflow: auto;
    }

    .right {
        display: flex;
        align-items: stretch;
        flex-grow: 0;
        flex-shrink: 0;
        height: 100%;

        .action {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            padding: 0 6px;
            transition: all @transitionDuration;
            height: 100%;

            > i {
                font-size: 16px;
                vertical-align: middle;
            }

            //&:global(.ant-popover-open),
            //&:hover {
            //    background: lighten(@primary-color, 40%);
            //}
        }

    }

    &[data-theme=dark] {
        background: @layout-header-background;
        box-shadow: 2px 0 6px lighten(@layout-header-background, 10%);

        .logo {
            background: @layout-header-background;
            box-shadow: none;
        }

        .right {
            color: #fff;
        }

        .action {
            &:global(.ant-popover-open),
            &:hover {
                background: lighten(@layout-header-background, 10%) !important;
            }
        }
    }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #000;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);